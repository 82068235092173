












































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import { vxm } from '@/store'
import CostCenterService from '@/services/economy/CostCenterService'
import CostCenterMenu from './Menu.vue'
import CostCenter from '@/models/economy/CostCenter'

@Component({
  components: {
    CostCenterMenu,
  },
})
export default class Edit extends Vue {
  private isSaving = false
  private data: CostCenter = null
  private original: CostCenter = null
  private rules = {}
  private error = ''

  public created(): void {
    this.data = new CostCenter()
    this.original = new CostCenter()
    this.rules = {
      name: [(v) => !!v || this.$t('c:validation:This field is required')],
      erpCode: [(v) => !!v || this.$t('c:validation:This field is required')],
    }
    this.load()
  }

  private get id(): number {
    return this.$route.params.id ? parseInt(this.$route.params.id) : null
  }

  private get isNew() {
    return !!this.$route.meta.isNew
  }

  private load() {
    if (!this.isNew) {
      CostCenterService.loadOne(this.id)
        .then((costCenter) => {
          this.data = costCenter
          this.original = costCenter.clone()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private get form() {
    return this.$refs.form as Vue & { validate: () => boolean }
  }

  private clickSave() {
    if (!this.form.validate()) {
      return
    }
    if (this.isNew) {
      CostCenterService.create(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save cost center')
        })
    } else {
      this.data.id = this.id
      CostCenterService.update(this.data)
        .then((_response) => {
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err, 'Could not save cost center')
        })
    }
  }

  private clickCancel() {
    this.$router.back()
  }
}
